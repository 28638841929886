//import { Component, OnInit } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { GeneralService } from '../../services/general.service';
import {EvenementService} from '../../services/evenement.service';
import {BetalingService} from '../../services/betaling.service';
import {InschrijvingService} from '../../services/inschrijving.service';

import {Evenement} from '../../entities/evenement.model';
import {Betaling} from '../../entities/betaling.model';
import { Inschrijving } from '../../entities/inschrijving.model';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-betaling',
  templateUrl: './betaling.component.html',
  styleUrls: ['./betaling.component.css'],
  providers: [GeneralService, EvenementService, BetalingService, InschrijvingService]
})
export class BetalingComponent implements OnInit {
  @ViewChild('childModal') public childModal:ModalDirective;
  //### specifieke variabelen: aan te passen
  tableName = "vwBetaling";
  primKeyField = 'isInschrijvingsID';
  primTableArray: Betaling[];
  selectedItem: Betaling;
  selectedInschrijving: Inschrijving;
  eventArray: Evenement[];
  searchField:string = "fullname";
  order: string = 'fullname';  
  editCheck: string;
  //eventSelectedId: number;

//### algemene variabelen: niet aan te passen
  title = 'betaling works!';
  counter:number; 
  sortAction:boolean;
  reverse: boolean = false;
  showGeneral: boolean = false;  
  submitted = false;      
  newKey: number;
  updateKeyID:number;
  removeKeyID:number;
  itemAdded:boolean;
  initialFieldValues;  
  initialFieldValue: string = "";
  initialFieldName : string = "";  
  errorMessage: string = "";
  subscription;
  
  constructor(private evenementService : EvenementService,
              private betalingService : BetalingService,
              private generalService: GeneralService,
              private inschrijvingService: InschrijvingService) { }

  ngOnInit() {
    this.eventDataOphalen();
    this.initialFieldValues = new Array();
    //maak code om events aan te passen na afsluiten van inschrijving
    this.editCheck = this.generalService.createEditCheck();
  }

  dataOphalen(eventID:number):void {
    var observable = this.betalingService.getFilteredData(eventID);
    //this.eventSelectedId = eventID;
    observable
    .subscribe(
      array => this.primTableArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
       this.generalService.giveLog('Tabel prijzen opgehaald uit database; ' + this.primTableArray.length + ' records');
       this.errorMessage = "";
      //  this.initialPrimTableArray = this.primTableArray;
      // this.initialPrimTableArray.forEach(element => {
      //  this.generalService.giveLog(element.prNaam);
      // });
       
      }      
    )
    this.newKey = 0;
  }

  eventDataOphalen(): void {
    this.evenementService.getAPI_Values()
      .subscribe(
        array => this.eventArray = array,
        err=> 
        {
          this.errorMessage = "Namen van lijsten niet opgehaald"
        },
     () => 
     {
       this.errorMessage = "";
       this.generalService.giveLog(this.eventArray[0].evNaam);
     }
     );
  }

  inschrijvingOpslaan(fieldName: string, newValue, isInschrijvingsID:number): void {
    var selectedInschrijving: Inschrijving;
    this.inschrijvingService.getOneInschrijving(isInschrijvingsID)
    .subscribe(
      instance => selectedInschrijving = instance,
      err=> 
      {
        this.errorMessage = "Inschrijving niet opgehaald";
      },
   () => 
   {
     this.errorMessage = "";
     
     if (fieldName == 'isBedragBetaald') {
       selectedInschrijving.isBedragBetaald = newValue;
     } else {
       selectedInschrijving.isTafelNr = newValue.toString();
     }
     this.generalService.giveLog('Nieuwe situatie: ' + JSON.stringify(selectedInschrijving));
     this.updateRecord(selectedInschrijving);
   }
   );

  }

  saveRecord(fieldName: string, newValue: number, id: number) {
  if(fieldName == this.initialFieldName && newValue.toString() != this.initialFieldValue) {
    let aantal: number = 0;
   this.inschrijvingOpslaan(fieldName, newValue ,id);    
  }
  else {
    console.log("geen wijziging " + fieldName + " - " + newValue);
  }

/*
  this.newItem = new Prijs (null,this.item.evEventID, naam, omschrijving, prijs, maxDeelnemers, header, omschrijving_underscore, volgorde);
  if(this.createItem) {
    var response;
    this.createItem = false;
    this.generalService.giveLog('Create Item:' + JSON.stringify(this.newItem));

    this.prijsService.addRecord(this.newItem)
    .pipe( finalize( () => this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, this.newItem.prOmschrijving) ) )
    .subscribe(
      gelukt => this.newItem,
      error =>  
      {
        this.generalService.giveLog('error bij wegschrijven');
        this.alertService.newAlert('Fout','niet opgeslagen',3000,this.alerts, this.newItem.prOmschrijving);
        this.generalService.giveLog(this.newItem);
      },
      () => 
      {
        this.generalService.giveLog(this.newItem);
        this.primTableArray.push(this.newItem);
        this.dataOphalen();
      }
    );
  }
  else {
    this.newItem.prPrijsID = this.selRecord.prPrijsID;
    this.updateRecord(this.newItem);
    this.generalService.giveLog('Create Item / update');
  }
    this.editItem = false;    
*/
}

  updateRecord(inschrijving: Inschrijving)
  {
    var observable = this.inschrijvingService.updateRecord(inschrijving);
    observable.subscribe((response) => {
      //this.generalService.giveLog(JSON.stringify(response));
      var callResponse = JSON.stringify(response);
      if (callResponse == '{"update":"OK"}') {
        this.generalService.giveLog("OK");
      } else {
        this.generalService.giveLog("not OK");
      }
    });
  }

 removeItem() {
  this.generalService.giveLog("delete: " + this.selectedItem.isInschrijvingsID);
  var observable = this.inschrijvingService.deleteRecord(this.selectedItem.isInschrijvingsID);
  observable.subscribe(
    (response) => {
    this.generalService.giveLog(JSON.stringify(response));
    },
    error =>
    {
      this.generalService.giveLog('Fout');
    },
    () => this.dataOphalen(this.selectedItem.isEventID)
  );
  this.hideChildModal();
}

  setOrder(value: string) {
    console.log(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    else {
      this.reverse = false;
    }
    this.order = value;
  }

  chooseFilterField(value){ 
    this.searchField = value;
    console.log("searchfield = " + value);
  }

initialValue(fieldName:string, fieldValue: string) {
  this.initialFieldName = fieldName;
  this.initialFieldValue = fieldValue;
  this.generalService.giveLog(fieldName + ": " + fieldValue)
}

    //### Functies voor modal
    public showChildModal(itemToDelete: Betaling):void {
      this.selectedItem = itemToDelete;
      this.childModal.show();
      this.generalService.giveLog('delete clicked for:' + itemToDelete.isInschrijvingsID);
      //this.idToDelete = itemToDelete.isInschrijvingsID;
    }

  public hideChildModal():void {
    this.childModal.hide();
  }
}

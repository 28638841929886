import {Injectable} from '@angular/core';
import {Http, Response, Headers, RequestOptions} from '@angular/http';

//import { Subject } from 'rxjs/Subject';
//import 'rxjs/add/operator/map';

import { IAlert } from '../entities/alert.model';


@Injectable()

export class AlertService {
    

    public newAlert(type: string, message: string, timeout:number, alerts: IAlert[], subject?: string) {
        var newAlert: IAlert;
        newAlert = {type:type,message:message,timeout:timeout,subject:subject};

        alerts.push(newAlert)
        setTimeout(() => alerts.splice(alerts.indexOf(newAlert),1), timeout);
    }
    
    public closeAlert(alert: IAlert, alerts: IAlert[]) {
        const index: number = alerts.indexOf(alert);
        alerts.splice(index, 1);
    }    
}

